<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import { required } from 'vuelidate/lib/validators'

/**
 * Starter component
 */
export default {
  page: {
    title: `Post Form`,
  },
  data() {
    return {
      title: "Posts",
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Posts",
          href: "/posts/posts",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      type: null,
      form:{
        title: '',
        heading: "",
        sub_heading: "",
        link: '',
        id: null,
      },
      yearError: null,
    };
  },
  computed:{
    years(){
      return this.$store.getters['option/years'];
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  validations:{
    form:{
      title: {
        required,
      },
      heading: {
        required,
      },
      sub_heading:{
        required,
      },
    }
  },
  methods:{
    async handleSubmit() {
      this.submit = true;
      this.$v.form.$touch();
      if(!this.$v.form.$anyError){
        this.$router.push(`/posts/posts/create/${encodeURIComponent(this.form.title)}/${encodeURIComponent(this.form.heading)}/${encodeURIComponent(this.form.sub_heading)}/${encodeURIComponent(this.form.link)}`)
      }
    },
  },
  async created(){
    this.type = 'create';
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create Post</h2>
                    <!-- <h2 v-if="type == 'edit'">Edit Chapter</h2> -->
                    <hr>
                  </div>
                <form action="#" @submit.prevent="">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Title:"
                        label-for="input-D"
                    >
                        <input v-model="form.title" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.title.$error }" placeholder="Enter post title" />
                        <div v-if="submit && $v.form.title.$error" class="invalid-feedback">
                            <span v-if="!$v.form.title.required">This field is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Heading:"
                        label-for="input-D"
                    >
                        <input v-model="form.heading" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.heading.$error }" placeholder="Enter post sub-title" />
                        <div v-if="submit && $v.form.heading.$error" class="invalid-feedback">
                            <span v-if="!$v.form.heading.required">This field is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Sub-heading:"
                        label-for="input-D"
                    >
                        <input v-model="form.sub_heading" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.sub_heading.$error }" placeholder="Enter post sub-title" />
                        <div v-if="submit && $v.form.sub_heading.$error" class="invalid-feedback">
                            <span v-if="!$v.form.sub_heading.required">This field is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Hyperlink:"
                        label-for="input-D"
                    >
                        <input v-model="form.link" type="url" name="name" class="form-control"  placeholder="Enter post link" />
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Next</button>
                <!-- <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button> -->
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>